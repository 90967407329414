<template>
  <div>
    <div class="filter-panel">
      <CSSelect style="margin-right: 0">
        <el-date-picker
          type="datetime"
          v-model="queryParams.setTimeStart"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          placeholder="请选择开始时间"
          prefix-icon="el-icon-time"
          :editable="false"
        >
        </el-date-picker>
      </CSSelect>
      <span class="inline-gray"></span>
      <CSSelect>
        <el-date-picker
          type="datetime"
          v-model="queryParams.setTimeEnd"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          placeholder="请选择结束时间"
          prefix-icon="el-icon-time"
          :editable="false"
        >
        </el-date-picker>
      </CSSelect>
      <CSSelect style="margin-right: 30px">
        <select v-model="queryParams.directorUserId">
          <option value="">全部设置主管</option>
          <option v-for="staff in staffList" :key="staff.id" :value="staff.id">
            {{ staff.name }}
          </option>
        </select>
      </CSSelect>
      <button class="btn btn-primary" @click="getRecordList()">查询</button>
    </div>
    <div class="result-panel">
        <CSTable :thead-top="filterHeight">
            <template v-slot:thead>
                <tr>
                    <th>设置时间</th>
                    <th>取消设置时间</th>
                    <th>设置主管</th>
                    <th>值班人员</th>
                </tr>
            </template>
            <template v-slot:tbody>
                <template v-if="recordList.length">
                    <tr v-for="record in recordList" :key="record.id">
                        <td>{{ record.setTime }}</td>
                        <td>{{ record.cancelSetTime }}</td>
                        <td>{{ record.director }}</td>
                        <td>{{ record.staff }}</td>
                    </tr>
                </template>
            </template>
        </CSTable>
      <Pagination />
    </div>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import { JOB_LEVEL } from "@/constant";
import { querySetStaffRecordUrl } from "@/requestUrl";
import dayjs from "dayjs";
import Pagination from "@/components/Pagination";
import CSTable from "@/components/common/CSTable";

export default {
  name: "SetForemanLog",
  created() {
    window.addEventListener("keydown", this.getRecordListDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.getRecordListDown);
  },
  deactivated(){
     window.removeEventListener("keydown", this.getRecordListDown);
  },
  components: {
      CSTable,
    CSSelect,
    Pagination,
  },
  async mounted() {
    this.staffList = await this.$vc.getStaffList({
      isOnTheJob: true,
      departmentCode: "",
      jobLevel: JOB_LEVEL.SUPERVISOR,
    });
    this.getRecordList();
    this.$vc.on(
      this.$route.path,
      "pagination",
      "page_event",
      (_currentPage) => {
        this.getRecordList(_currentPage);
      }
    );
      this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
      window.addEventListener('resize', () => {
          this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
      })
  },
  data() {
    return {
        filterHeight: 0,
      staffList: [],
      recordList: [],
      queryParams: {
        setTimeStart: dayjs().subtract(7, "day").format("YYYY-MM-DD 00:00"),
        setTimeEnd: dayjs().format("YYYY-MM-DD 23:59"),
        cancelSetTime: "",
        director: "",
        staff: "",
        directorUserId: "",
        staffUserId: "",
      },
    };
  },
  methods: {
    getRecordListDown(e) {
      if (e.keyCode == 13) {
        this.getRecordList();
      }
    },
    getRecordList(pageNo = 1, pageSize = 10) {
      this.$fly
        .post(querySetStaffRecordUrl, {
          ...this.queryParams,
          regionId: this.$vc.getCurrentRegion().code,
          setTimeStart: this.queryParams.setTimeStart + ":00",
          setTimeEnd: this.queryParams.setTimeEnd + ":59",
          pageNo,
          pageSize,
        })
        .then((res) => {
          this.recordList = res.data?.list || res.data || [];
          if (pageNo === 1) {
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data?.total || res.data.list.length,
              currentPage: pageNo,
              pageSize,
            });
          }
        });
    },
  },
};
</script>

<style lang="stylus" scoped></style>
